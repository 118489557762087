import { graphql } from 'gatsby';
import React from 'react';

import PageSection from 'ui-kit/page-section/page-section';

import PageLayout from 'components/layouts/page/page.layout';
import { NavPath } from 'components/navigation/navigation.props';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

const LandingPageProvider = ({ data }: { data: GatsbyTypes.LandingPageDataQuery }) => {
    const paragraphs = data.nodeLandingPage?.relationships?.field_landing_page_content;
    const metaData = data.nodeLandingPage?.field_meta_tags || {};
    const showShortFooter = data.nodeLandingPage?.field_use_short_footer || false;
    const hideSpecialtyAddress = data.nodeLandingPage?.field_hide_specialty_footer_addr || false;
    const hideHeaderMenu = data.nodeLandingPage?.field_hide_header_menu || false;
    const legalPaths: NavPath[] = (data.nodeLandingPage?.field_override_footer_menu_links || []).map((link) => ({
        isButton: false,
        langcode: 'en',
        link: {
            url: link?.url || ''
        },
        label: link?.title || ''
    }));

    if (!metaData.nodeTitle && data.nodeLandingPage.title) {
        metaData.nodeTitle = data.nodeLandingPage?.title;
    }

    return (
        <PageLayout
            metaData={metaData}
            isShortFooter={showShortFooter}
            legalPaths={legalPaths}
            hideSpecialtyAddress={hideSpecialtyAddress}
            hideHeaderMenu={hideHeaderMenu}
        >
            {paragraphs?.map((paragraph, index) => (
                <PageSection key={`page_section_${index}`} index={index} paragraph={paragraph}>
                    {paragraphToComponent(paragraph?.internal.type, paragraph, index)}
                </PageSection>
            ))}
        </PageLayout>
    );
};

export default LandingPageProvider;

export const query = graphql`
    query LandingPageData($language: String!, $nid: Int) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        nodeLandingPage(drupal_internal__nid: { eq: $nid }) {
            drupal_internal__nid
            title
            field_meta_tags {
                title
                description
                abstract
                keywords
                robots
            }
            field_use_short_footer
            field_hide_specialty_footer_addr
            field_hide_header_menu
            field_override_footer_menu_links {
                title
                url
            }
            relationships {
                field_landing_page_content {
                    ...supportedParagraphs
                    ... on paragraph__side_by_side_paragraphs {
                        id
                        relationships {
                            field_nested_paragraphs {
                                ...supportedParagraphs
                            }
                        }
                    }
                }
            }
        }
    }
`;
